import '../global.css'

import React from 'react'
import Head from 'next/head'
import {useRouter} from 'next/router'

import {getBrandOverrideFromCookie} from '@daedalus/core/src/_web/brand/business/persistData'
import {getBrandCodeFromUrlOrCookie} from '@daedalus/core/src/_web/brand/utils'
import {defaultBrand, getBrandByCode} from '@daedalus/core/src/brand/config'

import {PageProps as IndexPageProps} from './[[...slug]]'

import type {AppProps} from 'next/app'

/**
 * Could be pageProps from any page
 */
type AllPageProps = IndexPageProps | undefined

const App = ({Component, pageProps}: AppProps<AllPageProps>) => {
  // Note: this will be empty on initial render so we'll always render whatever the pageProps determines first, only use this for development/debugging
  const router = useRouter()
  const brandOverrideCode =
    router.query.brandOverride || getBrandOverrideFromCookie()
  const brandOverride =
    typeof brandOverrideCode === 'string' && getBrandByCode(brandOverrideCode)

  let brand = brandOverride || pageProps.brand
  if (!brand) {
    if (typeof window !== 'undefined') {
      brand = getBrandByCode(getBrandCodeFromUrlOrCookie(window._env.APP_ENV))
    } else {
      brand = defaultBrand
    }
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <link
          rel="icon"
          href={`/static/brands/${brand.code}_favicon.png`}
          sizes="any"
        />
        <title>{`${brand.name} | ${brand.tagline}`}</title>
        <meta
          name="description"
          content={brand.metaDescription}
          data-react-helmet="true"
        />
        <meta property="og:title" content={brand.name} />
        <meta property="og:description" content={brand.metaDescription} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`/static/brands/${brand.code}.png`}
        />
        <meta
          property="og:url"
          content={`https://${brand.siteAddresses.address}`}
        />
        {brand.cookieConsentId ? (
          <>
            <script
              type="text/javascript"
              id="osano-constent-mode"
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer ||[];
              function gtag(){dataLayer.push(arguments);}
              gtag('consent','default',{
                'ad_storage':'denied',
                'analytics_storage':'denied',
                'ad_user_data':'denied',
                'ad_personalization':'denied',
                'personalization_storage':'denied',
                'functionality_storage':'granted',
                'security_storage':'granted',
                'wait_for_update': 500
              });
              gtag("set", "ads_data_redaction", true);
            `
              }}
            />
            <style>{`.osano-cm-dialog,.osano-cm-widget{display: none;}`}</style>
            <script
              type="text/javascript"
              id="osano-cross-device-consent"
              dangerouslySetInnerHTML={{
                __html: `
              ;(function(w,o,d){w[o]=w[o]||function(){w[o][d].push(arguments)};w[o][d]=w[o][d]||[]})(window,'Osano','data');
              if(window.osanoMobileAppIdentifier) {
                window.Osano('userData', window.osanoMobileAppIdentifier);
              }`
              }}
            />
            <script
              async
              type="text/javascript"
              src={`https://cmp.osano.com/AzZuEAUQXGtUb3YcB/${brand.cookieConsentId}/osano.js`}
            />
          </>
        ) : null}
        {brand.meta &&
          Object.entries(brand.meta).map(([key, value]) => (
            <meta key={key} name={key} content={value} />
          ))}
      </Head>

      <Component {...pageProps} />
    </>
  )
}

export default App
